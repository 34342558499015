import * as React from "react";
import styled from "styled-components";
import COLORS from "@utils/COLORS";
import DEVICE from "@utils/DEVICE";
import SectionContainer from "@components/SectionContainer";
import { StaticImage } from "gatsby-plugin-image";
import TwoColumnTilesHero from "@components/TwoColumnTilesHero";

const IntroduceDetails = () => {
  return (
    <SectionContainer color="white" size="large">
      <Wrapper>
        <TwoColumnTilesHero
          title={`Wan!Pass（ワンパス）は\nペットオーナーに特化したおでかけアプリです。`}
          details={[
            {
              image: (
                <StaticImage
                  src="../../images/introduce_details/dog_owner.png"
                  alt="アプリユーザーは100%ドッグオーナー。"
                />
              ),
              text: "アプリユーザーは100%ドッグオーナー。",
            },
            {
              image: (
                <StaticImage
                  src="../../images/introduce_details/free_pop.png"
                  alt="無料！POPを置くだけで簡単導入！"
                />
              ),
              text: "無料！POPを置くだけで簡単導入！",
            },
            {
              image: (
                <StaticImage
                  src="../../images/introduce_details/store_policy.png"
                  alt="入店条件は各店舗で設定できます。"
                />
              ),
              text: "入店条件は各店舗で設定できます。",
            },
            {
              image: (
                <StaticImage
                  src="../../images/introduce_details/growing_businesses.png"
                  alt="大手企業含め、登録店舗増加中！"
                />
              ),
              text: "大手企業含め、登録店舗増加中！",
            },
          ]}
        />
      </Wrapper>
    </SectionContainer>
  );
};

export default IntroduceDetails;

const Wrapper = styled.div`
  background-color: ${COLORS.white};
  @media ${DEVICE.MOBILE} {
    display: block;
  }
`;
