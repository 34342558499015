import React, { useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";
import AutoScroll, { AutoScrollOptionsType } from "embla-carousel-auto-scroll";
import styled from "styled-components";
import DEVICE from "@utils/DEVICE";

type PropType = {
  slides: JSX.Element[]; // スライドに表示する要素
  slideWidth: number; // スライドの幅（px単位）
  autoScrollOptions?: AutoScrollOptionsType; // AutoScroll のオプションを渡す
};

const Carousel: React.FC<PropType> = ({
  slides,
  slideWidth,
  autoScrollOptions,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: true }, // 無限ループ
    [
      AutoScroll({
        speed: 1, // デフォルトの自動スクロール速度
        playOnInit: true, // デフォルトで自動再生を有効化
        ...autoScrollOptions, // ユーザーが指定したオプションをマージ
      }),
    ]
  );

  useEffect(() => {
    if (!emblaApi) return;

    // 再初期化時に自動再生を確保
    emblaApi.on("reInit", () => {
      const autoScroll = emblaApi.plugins()?.autoScroll;
      if (autoScroll && !autoScroll.isPlaying()) {
        autoScroll.play();
      }
    });
  }, [emblaApi]);

  return (
    <EmblaCarousel>
      <Viewport ref={emblaRef}>
        <Container>
          {slides.map((slide, index) => (
            <Slide key={index} slideWidth={slideWidth}>
              {slide}
            </Slide>
          ))}
        </Container>
      </Viewport>
    </EmblaCarousel>
  );
};

export default Carousel;

// Styled-components 定義
const EmblaCarousel = styled.div`
  overflow: hidden;
  width: 100%;
`;

const Viewport = styled.div`
  overflow: hidden;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  gap: 10px; /* スライド間の余白 */
`;

const Slide = styled.div<{ slideWidth: number }>`
  flex: 0 0 auto; /* 横幅を固定 */
  width: ${(props) => props.slideWidth}px; /* 呼び出し時に渡された幅 */
  height: auto; /* スライドの高さ */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 28px;

  img,
  .gatsby-image-wrapper {
    max-height: 100%; /* スライドの高さに収まるように設定 */
    max-width: 100%; /* スライドの幅に収まるように設定 */
    object-fit: contain; /* アスペクト比を維持しながら画像全体を表示 */
  }

  @media ${DEVICE.MOBILE} {
    width: ${(props) =>
      props.slideWidth / 1.4}px; /* モバイル時は幅を小さめに設定 */
    margin: 0 16px;
  }
`;
