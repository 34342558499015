import * as React from "react";
import LandingTop from "@components/business/LandingTop";
import DownloadDocument from "@components/business/DownloadDocument";
import LandingLayout from "@layouts/LandingLayout";
import IntroductionExample from "@components/business/IntroductionExample";
import IntroduceDetails from "@components/business/IntroduceDetails";
import IntroduceCompany from "@components/IntroduceCompany";
import DeliverDocument from "@components/business/DeliverDocument";
import DownloadDocumentForm from "@components/business/DownloadDocumentForm";
import { SeoProps } from "@layouts/Seo";
import RainbowLine from "@components/RainbowLine";
import { STATIC_OG_BUSINESS_LP, YOUTUBE } from "../../config/constants";
import HubspotEmbedCode from "@components/shared/HubspotEmbedCode";
import YoutubeVideoContainer from "@components/shared/YoutubeVideoContainer";
import { BusinessTracking } from "@components/business/tracking/BusinessTracking";

const SEO: SeoProps = {
  title:
    "Wan!Pass（ワンパス）-約560万世帯の愛犬家を無料で集客で集客するならワンパス！",
  description:
    "Wan!Pass（ワンパス）は日本で約560万世帯と言われるペットオーナー集客に特化した無料でご利用いただける集客プラットホームです。端末などの事前準備は一切不要。ご利用料金もずっと0円でカンタンにご利用いただけます。",
  image: STATIC_OG_BUSINESS_LP,
  noTitleTemplate: true,
};

const IndexPage = () => {
  return (
    <LandingLayout seo={SEO} type="business">
      <BusinessTracking.LandingPoint />
      <HubspotEmbedCode type="business_lp" />
      <RainbowLine />
      <LandingTop />
      <DownloadDocument />
      <IntroductionExample />
      <YoutubeVideoContainer youtubeVideoLink={YOUTUBE.BUSINESS_INTRODUCTION} />
      <IntroduceDetails />
      <IntroduceCompany />
      <DeliverDocument />
      <DownloadDocumentForm />
    </LandingLayout>
  );
};

export default IndexPage;
