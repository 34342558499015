import TitleWithRainbowLineBorder from "@components/TitleWithRainbowLineBorder";
import * as React from "react";
import styled from "styled-components";
import DEVICE from "@utils/DEVICE";
import ResponsiveColumns from "./ResponsiveColumns";
import { Body1 } from "./Typo";

type Props = {
  title: string;
  details: {
    text: string | JSX.Element;
    image: JSX.Element;
  }[];
};

const TwoColumnTilesHero = ({ title, details }: Props) => {
  // 配列を指定したサイズごとに分割するヘルパー関数
  const chunkArray = <T,>(array: T[], size: number): T[][] =>
    array.reduce((acc, _, index) => {
      if (index % size === 0) acc.push(array.slice(index, index + size));
      return acc;
    }, [] as T[][]);

  const groupedDetails = chunkArray(details, 2);

  return (
    <DetailContainer>
      <TitleWithRainbowLineBorder text={title} shouldAlignCenter={true} />
      <ResponsiveColumnsContainer>
        {groupedDetails.map((group, groupIndex) => (
          <ResponsiveColumns key={groupIndex} gap={5}>
            {group.map((detail, index) => (
              <Content key={index}>
                <ImageWrapper>{detail.image}</ImageWrapper>
                <TextWrapper>
                  <Body1>{detail.text}</Body1>
                </TextWrapper>
              </Content>
            ))}
          </ResponsiveColumns>
        ))}
      </ResponsiveColumnsContainer>
    </DetailContainer>
  );
};

export default TwoColumnTilesHero;

const DetailContainer = styled.div`
  margin-bottom: 80px;
  &:last-child {
    margin-bottom: 40px;
  }
  @media ${DEVICE.MOBILE} {
    margin: 0 auto;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 15px;
    }
  }
`;

const ResponsiveColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  margin-top: 40px;
  @media ${DEVICE.MOBILE} {
    display: block;
    margin-top: 20px;
  }
`;

const Content = styled.div`
  padding: 32px 31.5px 0px 31.5px;
  height: 100%;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;

  @media ${DEVICE.MOBILE} {
    width: 100%;
    margin: 14px auto;
  }
`;

const TextWrapper = styled.div`
  margin-bottom: 10px;
  text-align: center;
`;

const ImageWrapper = styled.div`
  padding: 30px;
  text-align: center;
`;
