import DEVICE from "@utils/DEVICE";
import * as React from "react";
import styled from "styled-components";

type Props = {
  text: string;
  shouldAlignCenter?: boolean; // PC表示の場合のみ適応されます。
  shouldSpAlignCenter?: boolean; // SP表示の場合のみ適応されます。
};

const TitleWithRainbowLineBorder = ({
  text,
  shouldAlignCenter = false,
  shouldSpAlignCenter = false,
}: Props) => {
  return (
    <Wrapper
      shouldAlignCenter={shouldAlignCenter}
      shouldSpAlignCenter={shouldSpAlignCenter}
    >
      <h2 className="lp-rainbow-title">{text}</h2>
    </Wrapper>
  );
};

export default TitleWithRainbowLineBorder;

const Wrapper = styled.div<{
  shouldAlignCenter: boolean;
  shouldSpAlignCenter: boolean;
}>`
  text-align: ${(p) => (p.shouldAlignCenter ? "center" : "left")};

  @media ${DEVICE.MOBILE} {
    h2.lp-rainbow-title {
      text-align: ${(p) => (p.shouldSpAlignCenter ? "center" : "left")};
    }
  }
`;
