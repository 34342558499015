/**
 * LandingLayout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import styled from "styled-components";
import Seo, { SeoProps } from "./Seo";
import "./LandingLayout.css";
import Footer, { FooterLinkProps } from "./Footer";
import { Body2 } from "@components/Typo";
import { LayoutType } from "./LayoutType";
import AppRoot from "../AppRoot";

type LandingLayoutProps = React.PropsWithChildren & {
  seo: SeoProps;
  type: LayoutType;
  footerLinks?: FooterLinkProps[];
  footerAddition?: JSX.Element;
};

const LandingLayout = ({
  children,
  seo,
  type,
  footerLinks,
  footerAddition,
}: LandingLayoutProps) => {
  return (
    <AppRoot>
      <Seo {...seo}></Seo>
      <Wrapper>{children}</Wrapper>
      <Body2>
        <Footer
          layoutType={type}
          additionalLinks={footerLinks}
          footerAddition={footerAddition}
        />
      </Body2>
    </AppRoot>
  );
};

export default LandingLayout;

const Wrapper = styled.section`
  width: 100%;
  margin: auto;
`;
