import TitleWithRainbowLineBorder from "@components/TitleWithRainbowLineBorder";
import * as React from "react";
import styled from "styled-components";
import DEVICE from "@utils/DEVICE";
import SectionContainer from "@components/SectionContainer";
import { Body2 } from "@components/Typo";
import { StaticImage } from "gatsby-plugin-image";
import LogoCarousel from "./LogoCarousel";

const IntroductionExample = () => {
  return (
    <SectionContainer size="medium" color="lightGray">
      <Wrapper>
        <TitleWithRainbowLineBorder
          text="導入事例"
          shouldAlignCenter={true}
          shouldSpAlignCenter={true}
        />
        <ImageWrapper>
          <StaticImage
            src="../../images/introduction_example/4000_facilities_adopted.png"
            alt="導入施設数4000以上"
            placeholder="blurred"
            layout="constrained"
            width={340}
          />
        </ImageWrapper>

        <LogoCarousel></LogoCarousel>

        <NoteWrapper>
          <Body2>※2024年12月時点</Body2>
        </NoteWrapper>
      </Wrapper>
    </SectionContainer>
  );
};

export default IntroductionExample;

const Wrapper = styled.div`
  margin: 0 auto;
  @media ${DEVICE.MOBILE} {
    display: block;
    padding: 30px 0;
  }
`;

const ImageWrapper = styled.div`
  padding: 0px 75px;
  margin-top: 40px;
  text-align: center;
  @media ${DEVICE.MOBILE} {
    margin: 0 24px auto;
    margin-top: 20px;
    padding: 20px 0px;
  }
`;

const NoteWrapper = styled.div`
  padding-top: 50px;
  text-align: center;
  @media ${DEVICE.MOBILE} {
    padding-top: 20px;
  }
`;
