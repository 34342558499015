import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Carousel from "@components/shared/Carousel";
import COLORS from "@utils/COLORS";
import styled from "styled-components";

const LogoCarousel = () => {
  const slides = [
    {
      key: "livemax",
      element: (
        <StaticImage
          src="../../images/introduction_example/logs/livemax.png"
          alt="リブマックス"
          layout="constrained"
        />
      ),
    },
    {
      key: "villa-fontaine",
      element: (
        <StaticImage
          src="../../images/introduction_example/logs/villa_fontaine.png"
          alt="住友ヴィラフォンテーヌ"
          layout="constrained"
        />
      ),
    },
    {
      key: "minamiboso-kanko",
      element: (
        <StaticImage
          src="../../images/introduction_example/logs/minamiboso_kanko.png"
          alt="南房総観光協会"
          layout="constrained"
        />
      ),
    },
    {
      key: "narita-yume-farm",
      element: (
        <StaticImage
          src="../../images/introduction_example/logs/narita_yume_farm.png"
          alt="成田ゆめ牧場"
          layout="constrained"
        />
      ),
    },
    {
      key: "pica",
      element: (
        <StaticImage
          src="../../images/introduction_example/logs/pica.png"
          alt="PICA"
          layout="constrained"
        />
      ),
    },
    {
      key: "fujita-kanko",
      element: (
        <StaticImage
          src="../../images/introduction_example/logs/fujita_kanko.png"
          alt="藤田観光グループ"
          layout="constrained"
        />
      ),
    },
    {
      key: "tobu-worldsquare",
      element: (
        <StaticImage
          src="../../images/introduction_example/logs/tobu_worldsquare.png"
          alt="東武ワールドスクエア"
          layout="constrained"
        />
      ),
    },
    {
      key: "shonan-tsite",
      element: (
        <StaticImage
          src="../../images/introduction_example/logs/shonan_tsite.png"
          alt="湘南Ｔ－ＳＩＴＥ"
          layout="constrained"
        />
      ),
    },
  ];

  const slides2 = [
    {
      key: "kintetsu-fudosan",
      element: (
        <StaticImage
          src="../../images/introduction_example/logs/kintetsu_fudosan.png"
          alt="近鉄不動産"
          layout="constrained"
        />
      ),
    },
    {
      key: "karuizawa-kanko",
      element: (
        <StaticImage
          src="../../images/introduction_example/logs/karuizawa_kanko.png"
          alt="軽井沢観光協会"
          layout="constrained"
        />
      ),
    },
    {
      key: "odakyu-hakone",
      element: (
        <StaticImage
          src="../../images/introduction_example/logs/odakyu_hakone.png"
          alt="小田急箱根"
          layout="constrained"
        />
      ),
    },
    {
      key: "murasaki-sports",
      element: (
        <StaticImage
          src="../../images/introduction_example/logs/murasaki_sports.png"
          alt="ムラサキスポーツ"
          layout="constrained"
        />
      ),
    },
    {
      key: "weins-toyota-kanagawa",
      element: (
        <StaticImage
          src="../../images/introduction_example/logs/weins_toyota_kanagawa.png"
          alt="ウエインズトヨタ神奈川"
          layout="constrained"
        />
      ),
    },
    {
      key: "hakone-pirateship",
      element: (
        <StaticImage
          src="../../images/introduction_example/logs/hakone_pirateship.png"
          alt="箱根郵船（箱根海賊船 富士急G）"
          layout="constrained"
        />
      ),
    },
    {
      key: "jukkoku-toge",
      element: (
        <StaticImage
          src="../../images/introduction_example/logs/jukkoku_toge.png"
          alt="十国峠"
          layout="constrained"
        />
      ),
    },
    {
      key: "resol-nasu-wan",
      element: (
        <StaticImage
          src="../../images/introduction_example/logs/resol_nasu_wan.png"
          alt="リソルグループ（那須ワン）"
          layout="constrained"
        />
      ),
    },
  ];

  return (
    <Wrapper>
      <CarouselWrapper>
        <Carousel
          slides={slides.map((slide) => slide.element)}
          slideWidth={200}
        />
      </CarouselWrapper>
      <CarouselWrapper>
        <Carousel
          slides={slides2.map((slide) => slide.element)}
          slideWidth={200}
        />
      </CarouselWrapper>
    </Wrapper>
  );
};

export default LogoCarousel;

const Wrapper = styled.div`
  margin-top: 32px;
  background-color: ${COLORS.white};
`;

const CarouselWrapper = styled.div`
  padding: 24px 0;
`;
